<template>
  <v-container fluid class="pa-6">
    <v-row no-gutters align="center"
      ><v-col cols="12" md="6" align="start"
        ><h2>
          <v-icon color="primary" large left>{{ icon }}</v-icon
          >{{ title }} <span v-if="totalCount">({{ totalCount }})</span>
        </h2> </v-col
      ><v-col cols="12" md="6" align="end">
        <v-btn
          v-if="userCan('import-productivity')"
          class="primary--text mx-2"
          :to="{ name: 'upload-productivity' }"
          >Upload <v-icon right color="primary">mdi-file-upload</v-icon>
        </v-btn>
        <v-btn
          v-if="userCan('import-productivity')"
          class="primary--text  mx-2"
          :to="{ name: 'generate-productivity' }"
          >Generate <v-icon right color="primary">mdi-finance</v-icon>
        </v-btn>
      </v-col></v-row
    >
    <v-data-iterator
      :items="items"
      :options="pagination"
      :items-per-page.sync="pagination.rowsPerPage"
      :server-items-length.sync="totalCount"
      :page.sync="pagination.page"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: itemsPerPageOptions
      }"
    >
      <template v-slot:header>
        <v-toolbar color="primary " dark class="mb-1 my-4 ">
          <v-dialog
            ref="dialog_from"
            v-model="modal_from"
            :return-value.sync="pagination.date_from"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(pagination.date_from, 'MMM')"
                label="Pick a range"
                prepend-inner-icon="mdi-calendar"
                readonly
                class="px-2"
                hide-details
                solo
                light
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="pagination.date_from"
              color="primary"
              type="month"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal_from = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog_from.save(pagination.date_from)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-dialog
            ref="dialog_to"
            v-model="modal_to"
            :return-value.sync="pagination.date_to"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Pick a range"
                class="px-2"
                prepend-inner-icon="mdi-calendar"
                readonly
                :value="formatDate(pagination.date_to, 'MMM')"
                hide-details
                solo
                light
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="pagination.date_to"
              color="primary"
              type="month"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal_to = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog_to.save(pagination.date_to)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-autocomplete
            :items="hrSuppliers"
            v-model="pagination.hr_supplier_id"
            label="HR supplier"
            item-text="name"
            auto-select-first
            multiple
            item-value="id"
            clearable
            name="HR supplier"
            hide-selected
            hide-details
            solo
            light
            class="font-weight-medium mx-3"
          >
          </v-autocomplete>
        </v-toolbar>
      </template>
      >
      <template v-slot:no-data>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense
            ><v-toolbar-title>Info</v-toolbar-title></v-toolbar
          >
          <v-alert
            border="bottom"
            colored-border
            color="primary"
            elevation="2"
            type="info"
          >
            No data available
          </v-alert>
        </v-card>
      </template>
      >
      <template v-slot:loading>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense
            ><v-toolbar-title>Loading data...</v-toolbar-title></v-toolbar
          >
          <v-alert border="bottom" colored-border color="primary" elevation="2">
            <v-progress-circular indeterminate color="primary" />
          </v-alert>
        </v-card>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            class="pa-4"
            cols="12"
            sm="6"
            md="6"
            lg="6"
          >
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card class="dekra-card" :class="{ 'on-hover': hover }">
                <v-card-title class="subheading font-weight-bold">
                  <v-row align="center">
                    <v-col cols="2">
                      <v-avatar v-if="item.company.logo_preview" size="56" tile
                        ><v-img contain :src="item.company.logo_preview"></v-img
                      ></v-avatar>
                    </v-col>
                    <v-col cols="8" class="text-left text-truncate">
                      <span>{{ item.company.name }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            large
                            v-bind="attrs"
                            v-on="on"
                            :to="{
                              name: 'show-' + [routeName],
                              params: { id: item.id }
                            }"
                          >
                            <v-icon>mdi-eye-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>View</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-list two-line dense>
                  <v-list-item v-if="item.number_of_employees">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="primary--text headline">{{
                          item.number_of_employees
                        }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-bold"
                        >Number of employees</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="primary--text headline">{{
                          item.planned_productive_hours
                        }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-bold"
                        >Planned productive hours</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="primary--text headline">{{
                          item.productive_hours
                        }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-bold"
                        >Productive hours</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="primary--text headline">{{
                          item.unproductive_hours
                        }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-bold"
                        >Unproductive hours</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="primary--text headline">{{
                          item.uh_sickness
                        }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-bold"
                        >Sickness</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="primary--text headline">{{
                          item.uh_vacations
                        }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-bold"
                        >Vacation</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="primary--text headline">{{
                          item.uh_absence_without_excuse
                        }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-bold"
                        >Absence</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-actions>
                  <div class="caption mx-2" v-if="item['report_for_date']">
                    {{ formatDate(item["report_for_date"], "MMM YYYY ") }}
                  </div>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import date from "@/mixins/date";
import file from "@/mixins/file";
import permission from "@/mixins/permission";
export default {
  mixins: [date, file, permission],
  name: "ProductivityList",
  props: {
    routeName: {
      type: String,
      required: true
    },
    namespace: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true,
      default: "Title"
    },
    icon: {
      type: String,
      required: true,
      default: "mdi-help"
    }
  },
  data() {
    return {
      maxDate: new Date().toISOString().substr(0, 10),
      currentId: null,
      modal_from: false,
      modal_to: false,
      itemsPerPageOptions: [12, 24, 48, -1]
    };
  },
  computed: {
    ...mapGetters({
      hrSuppliers: "companies/hrSuppliers"
    }),
    loading: {
      get() {
        return this.$store.getters[this.namespace + "/loading"];
      }
    },
    totalCount: {
      get() {
        return this.$store.getters[this.namespace + "/total"];
      }
    },
    items: {
      get() {
        return this.$store.getters[this.namespace + "/items"];
      },
      set() {
        this.$store.dispatch(this.namespace + "/getItems", false);
      }
    },
    pagination: {
      get() {
        return this.$store.getters[this.namespace + "/pagination"];
      },
      set(value) {
        this.$store.dispatch(this.namespace + "/setPagination", value);
      }
    }
  },
  created() {
    this.getData();
  },
  async mounted() {
    await this.$store.dispatch("companies/getSuppliers", {
      name: "hrSuppliers",
      company_group: "hr-suppliers"
    });
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    async getData() {
      await this.$store.dispatch(this.namespace + "/getItems", false);
    },
    getItemValue(item, name) {
      if (name.includes(".")) {
        let nameArray = name.split(".");
        return item[nameArray[0]][nameArray[1]];
      }
      return item[name];
    }
  }
};
</script>
<style scoped>
.dekra-card {
  border-bottom: 5px solid #017d40;
  transition: transform 0.2s;
}
.dekra-card.on-hover {
  transform: scale(1.04);
}
</style>
